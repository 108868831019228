import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SiteContext from 'site/SiteContext';
import { withRouter } from 'react-router';
import { EventsSection, HomepageSectionHeader, Card} from 'dma-ui-kit';
import ImageNotFound from 'assets/resultmissing.png';
import { fromUnixTime, isBefore } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const HomePageEventsWidget = props => {
    const { history } = props;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link } = props.paragraph.attributes;

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');
    const followCTA = () => { history.push(ctaLink); };

    const [eventList, setEventList] = useState([]);

    const showCurrentEvents = () => {
        const results = eventList.map((result, index) => {
            const image = result.featured_image && result.featured_image[0] ? result.featured_image[0] : ImageNotFound;

            var dateIndex = 0;
            while (isBefore(fromUnixTime(result.field_date_rrule[dateIndex]), new Date))
                if (result.field_date_rrule[dateIndex + 1])
                    dateIndex++;

            return (
                <Link key={index} to={{ pathname: result.url[0] }}>
                    <Card 
                        image={image} 
                        caption={formatInTimeZone(fromUnixTime(result.field_date_rrule[dateIndex]), 'America/Chicago', 'EEE, MMM do yyyy, h:mm aaa')} 
                        title={result.title[0]}
                    />
                </Link>
            );
        });
        return results;        
    };

    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const getEvents = async () => {
        const date = Date.now() / 1000;
        const query = 
        { 
            "query": 
            { 
                "bool": 
                { 
                    "must": 
                    [ 
                        { "query_string" : { "query": "event", "fields": ["type"]} },
                        { "range" : { "field_date_rrule" : { "gte" : `${date}` } }},
                        { "match": { "status": true }}
                    ]
                }
            }, 
            "sort": [ 
                { "promote": { "order" : "desc"} },
                { "field_date_rrule": { "order" : "asc" }}
            ], 
            "size": "3" 
        };

        const res = await axios.post(searchEndpoint, query);

        if (pullEvents)
        {
            const hits = res.data.hits.hits.map(hit => ({ ...hit._source }));
            setEventList(hits);
        }
    };

    let pullEvents = true;

    useEffect(() => {
        getEvents();
        return () => {
            pullEvents = false;
        };
    }, [pullEvents]);

    return (
        <>
            <EventsSection
                header={
                    <HomepageSectionHeader
                        boldHeadline={field_title_bold}
                        lightHeadline={field_title_light}
                        description={field_subtitle}
                        action={followCTA}
                        actionLabel={field_cta_link.title}
                    />
                }
            >
                {showCurrentEvents()}
            </EventsSection>
        </>
    );
};

export default withRouter(HomePageEventsWidget);
