import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Card, ContentBlock, H4, Image, Tag, WhatsNewSection, HomepageSectionHeader } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';

const HomePageVirtualWidget = props => {

    const { history, paragraph } = props;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link } = paragraph.attributes;
    const { field_exhibits } = paragraph.relationships;

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');

    // If the link isn't relative, open in a new tab
    const followCTA = () => { ctaLink.includes('http') ? window.open(ctaLink, "_blank") : history.push(ctaLink); };
    
    const showVirtualExhibits = () => {
        const results = field_exhibits.data.map((exhibit, index) => {
            const { field_title, field_cta_url } = exhibit.attributes;

            const label = field_cta_url.title ? field_cta_url.title : translateInterfaceText("View Exhibit");
            const url = field_cta_url.uri.replace('internal:', '');

            const image = exhibit.relationships.field_image.data.relationships.field_media_image.data.attributes.uri.url;

            const content = (
                <ContentBlock 
                    headline={<></>}
                    content={<H4 dark>{field_title}</H4>}
                    action={<Button variant="tertiary" color="white" label={label} />}
                />
            );
    
            return index === 0 ? (
              <Link key={`${label}_${index}`} to={{ pathname: url }} target={url.includes('http') ? '_blank' : null}>
                <Image key={`${label}_${index}`} src={image} content={content} /> 
              </Link>
            ) : (
              <Link key={`${label}_${index}`} to={{ pathname: url }} target={url.includes('http') ? '_blank' : null}>
                <Card variant="horizontal" image={image} title={field_title} />
              </Link>
            );
        });

        return results;
    };

    return (
        <>
            <WhatsNewSection
                header={
                    <HomepageSectionHeader
                        boldHeadline={field_title_bold}
                        lightHeadline={field_title_light}
                        description={field_subtitle}
                        action={followCTA}
                        actionLabel={field_cta_link.title}
                    />
                }
            >
                {showVirtualExhibits()}
            </WhatsNewSection>
        </>
    );
};

export default withRouter(HomePageVirtualWidget);
