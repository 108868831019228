import { useHistory } from 'react-router-dom';
import { translateInterfaceText } from 'helpers/translation';
import { CollectionsSection, HomepageSectionHeader, H2, Image, breakpoints } from 'dma-ui-kit';
import useBreakpoint from 'use-breakpoint';

const HomePageCollectionWidget = props => {
    const { breakpoint } = useBreakpoint(breakpoints);
    const { attributes, relationships } = props.paragraph;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link } = attributes;

    const history = useHistory();

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');
    const followCTA = () => { history.push(ctaLink); };

    const collectionActions = [];
    const collectionContents = [];
    const collectionImages = [];

    const exploreCollection = translateInterfaceText('Explore Collection');

    relationships.field_collections.data.map((collection, index) => {
        const { field_query, field_title } = collection.attributes;
        const { field_image_desktop, field_image_tablet, field_image_mobile } = collection.relationships;

        const getImageSource = () => {
            switch(breakpoint) {
                case "mobile":
                    return field_image_mobile;
                case "tablet":
                case "tabletSmall":
                    return field_image_tablet;
                default:
                    return field_image_desktop;
            };
        };

        collectionActions.push( () => window.location.assign(`/art/collection/search?s=${field_query}&p=1`) );
        collectionContents.push(<H2>{field_title}</H2>);
        collectionImages.push(<Image key={index} src={getImageSource().data?.attributes?.uri?.url} />);
    });

    return (
        <>
            <CollectionsSection
                header={
                    <HomepageSectionHeader
                        boldHeadline={field_title_bold}
                        lightHeadline={field_title_light}
                        description={field_subtitle}
                        action={followCTA}
                        actionLabel={field_cta_link.title}
                    />
                }
                actionLabel={exploreCollection}
                actions={collectionActions}
                contents={collectionContents}
                images={collectionImages}
            />
        </>
    );
};

export default HomePageCollectionWidget;
