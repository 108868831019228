import React from 'react';
// Pages
import BasicPage from 'components/pages/drupal/content_types/BasicPage';
import EventPage from 'components/pages/drupal/content_types/EventPage';
import DefaultPage from 'components/pages/drupal/content_types/DefaultPage';
import NewsletterManagement from 'components/pages/drupal/content_types/NewsletterManagement';
import NewsPage from 'components/pages/drupal/content_types/NewsPage';
import ExhibitionPage from 'components/pages/drupal/content_types/ExhibitionPage';
import AudioRecording from 'components/pages/drupal/content_types/AudioRecording';
import PublicationPage from 'components/pages/drupal/content_types/PublicationPage';
import PastExhibitionPage from 'components/pages/drupal/content_types/PastExhibitionPage';

// Paragraphs

import HomePageCollectionWidget from 'components/organisms/paragraph/HomePageCollectionWidget';
import HomePageEventsWidget from 'components/organisms/paragraph/HomePageEventsWidget';
import HomePageExhibitionWidget from 'components/organisms/paragraph/HomePageExhibitionWidget';
import HomePageHeroWidget from 'components/organisms/paragraph/HomePageHeroWidget';
import HomePageNewsWidget from 'components/organisms/paragraph/HomePageNewsWidget';
import HomePageVirtualWidget from 'components/organisms/paragraph/HomePageVirtualWidget';

import ContentHeader from 'components/organisms/paragraph/ContentHeader';
import ContentText from 'components/organisms/paragraph/ContentText';
import ContentQuote from 'components/organisms/paragraph/ContentQuote';
import SponsorBlock from 'components/organisms/paragraph/SponsorBlock';
import SponsorGroup from 'components/organisms/paragraph/SponsorGroup';
import Sponsor from 'components/organisms/paragraph/Sponsor';

import Carousel from 'components/organisms/paragraph/HeroCarousel';
import HeroCarouselItem from 'components/molecules/fields/HeroCarouselItem';
import HeroStack from 'components/organisms/paragraph/HeroStack';
import HeroStackItem from 'components/molecules/fields/HeroStackItem';

import CollectionDisclaimer from 'components/organisms/paragraph/CollectionDisclaimer';
import CollectionSearch from 'components/organisms/paragraph/CollectionSearch';
import CollectionSearchBar from 'components/organisms/paragraph/CollectionSearchBar';
import ExhibitionSearch from 'components/organisms/paragraph/ExhibitionSearch';
import NewsContactBlock from 'components/organisms/paragraph/NewsContactBlock';
import NewsFooter from 'components/organisms/paragraph/NewsFooter';
import NewsSearch from 'components/organisms/paragraph/NewsSearch';
import PastExhibitionSearch from 'components/organisms/paragraph/PastExhibitionSearch';
import SiteSearch from 'components/organisms/paragraph/SiteSearch';

import Newsletter from 'components/organisms/paragraph/Newsletter';
import NewsletterForm from 'components/organisms/paragraph/NewsletterForm';

import QuoteHero from 'components/organisms/paragraph/QuoteHero';
import QuoteBoxImageParagraph from 'components/organisms/paragraph/QuoteBoxImage';

import IndexBlock from 'components/organisms/paragraph/IndexBlock';
import IndexCard from 'components/organisms/paragraph/IndexCard';

import FeaturedNews from 'components/organisms/paragraph/FeaturedNews';
import IssuuEmbed from 'components/organisms/paragraph/IssuuEmbed';

import SlideShow from 'components/organisms/paragraph/SlideShow';
import Programs from 'components/organisms/paragraph/Programs';
import Press from 'components/organisms/paragraph/Press';
import PressItem from 'components/organisms/paragraph/PressItem';

import CerosEmbed from 'components/organisms/paragraph/CerosEmbed';
import CrowdriffEmbed from 'components/organisms/paragraph/CrowdriffEmbed';

import YoutubeVideo from 'components/organisms/paragraph/YoutubeVideo';
import RelatedEvents from 'components/organisms/paragraph/RelatedEvents';
import UpcomingEvents from 'components/organisms/paragraph/UpcomingEvents';

import CTAButton from 'components/organisms/paragraph/CTAButton';
import RecentNews from 'components/organisms/paragraph/RecentNews';
import RecordingList from 'components/organisms/paragraph/RecordingList';

import EventCalendar from 'components/organisms/paragraph/EventCalendar';





import Accordion from 'components/organisms/paragraph/Accordion';
import AccordionItem from 'components/organisms/paragraph/AccordionItem';

import PageHeadline from 'components/organisms/paragraph/PageHeadline';

import CollectionImage from 'components/organisms/paragraph/CollectionImage';
import CollectionList from 'components/organisms/paragraph/CollectionList';
import CollectionListItem from 'components/organisms/paragraph/CollectionListItem';
import ContentBlockParagraph from 'components/organisms/paragraph/ContentBlockParagraph';

// Fields
import TwoColumnParagraph from 'components/organisms/paragraph/TwoColumn';

export const getPageType = (data) => {
    if (!data.data || !data.data.type) throw new Error('Failed to getPageType');

    const pageType = data.data.type.split('--')[1];
    switch (pageType) {
        case "audio_recording":
            return AudioRecording;
        case 'event':
            return EventPage;
        case 'exhibitions':
            return ExhibitionPage;
        case "newsletter_management":
            return NewsletterManagement;
        case "news":
            return NewsPage;
        case "past_exhibition":
            return PastExhibitionPage;
        case "publication":
            return PublicationPage;
        case 'home_page':
        case 'landing_page':
        case 'page':
            return BasicPage;
        default:
            return DefaultPage;
    }
};

export const generatePageParagraphs = (paragraphs, pageTitle='') => 
    // eslint-disable-next-line array-callback-return
    paragraphs.map((paragraph, ind) => {
        if (paragraph && paragraph.type)
        {
            const Paragraph = getParagraphTypeHash(paragraph.type.split('--')[1]);
            if (Paragraph) {
                // need a better way of passing current page reference than the title
                return <Paragraph key={ind} paragraph={paragraph} pageTitle={pageTitle} />;
            }
        }
        console.log(`Missing Paragraph: Index ${ind}`);
        return <></>;

    }).filter(Boolean);

export const generateChildren = (relationships) => 
    // eslint-disable-next-line array-callback-return
    Object.keys(relationships).map((relationship, ind) => {

        const Paragraph = getParagraphTypeHash(relationship);

        if (Paragraph) {
            return <Paragraph key={ind} paragraph={relationships[relationship].data} />;
        }
    }).filter(Boolean);

export const generateChildrenFromList = (children) => 
    children.map((paragraph, ind) => {
        const Paragraph = getParagraphTypeHash(paragraph.type.split('--')[1]);

        if (Paragraph) {
            return <Paragraph key={ind} paragraph={paragraph} />;
        }
    }).filter(Boolean);

export const getParagraphTypeHash = (relationshipKey) => {
    return paragraphTypeHash[relationshipKey];
};

const paragraphTypeHash = {
    accordion: Accordion,
    accordion_item: AccordionItem,
    ceros_embed: CerosEmbed,
    collection_disclaimer: CollectionDisclaimer,
    collection_image: CollectionImage,
    collection_list: CollectionList,
    collection_list_item: CollectionListItem,
    collection_search: CollectionSearch,
    collection_search_bar: CollectionSearchBar,
    content_block: ContentBlockParagraph,
    content_header: ContentHeader,
    content_quote: ContentQuote,
    content_text: ContentText,
    crowdriff_embed: CrowdriffEmbed,
    cta_button: CTAButton,
    event_calendar: EventCalendar,
    exhibition_search: ExhibitionSearch,
    featured_news: FeaturedNews,
    hero_carousel: Carousel,
    hero_carousel_item: HeroCarouselItem,
    hero_stack: HeroStack,
    hero_stack_item: HeroStackItem,
    homepage_collection_widget: HomePageCollectionWidget,
    homepage_events_widget: HomePageEventsWidget,
    homepage_exhibition_widget: HomePageExhibitionWidget,
    homepage_hero_widget: HomePageHeroWidget,
    homepage_news_widget: HomePageNewsWidget,
    homepage_virtual_widget: HomePageVirtualWidget,
    index_block: IndexBlock,
    index_card: IndexCard,
    issuu_embed: IssuuEmbed,
    news_contact_block: NewsContactBlock,
    news_footer: NewsFooter,
    news_search: NewsSearch,
    newsletter: Newsletter,
    newsletter_form: NewsletterForm,
    page_headline: PageHeadline,
    past_exhibitions_search: PastExhibitionSearch,
    press_block: Press,
    press_item: PressItem,
    programs: Programs,
    quote_box_image: QuoteBoxImageParagraph,
    quote_hero: QuoteHero,
    recent_news: RecentNews,
    recording_list: RecordingList,
    related_events: RelatedEvents,
    site_search: SiteSearch,
    slide_show: SlideShow,
    sponsor: Sponsor,
    sponsor_block: SponsorBlock,
    sponsor_group: SponsorGroup,
    two_column: TwoColumnParagraph,
    upcoming_events: UpcomingEvents,
    youtube_video: YoutubeVideo
};


