import React from 'react';
import { withRouter } from 'react-router';
import { HeroCarousel, Body, grey400 } from 'dma-ui-kit';
import { generateChildrenFromList } from 'cms/parsers';

const Carousel = (props) => {
  const { history, paragraph } = props;
  const { attributes, relationships } = paragraph;
  const { field_light_text, field_bold_text, field_hero_body, field_cta_link, field_reverse } = attributes;

  const children = generateChildrenFromList(relationships.field_hero_carousel_items?.data);

  const content = (<Body variant="large" bold color={grey400}>{field_hero_body}</Body>);
  
  // Due to how Drupal handles internal urls, strip garbage
  const followCTA = () => { history.push(field_cta_link.uri.replace('internal:', '')); };
  
  return (
    <HeroCarousel
      boldHeadline={field_bold_text}
      lightHeadline={field_light_text}
      content={content}
      action={field_cta_link !== null ? followCTA : undefined}
      actionLabel={field_cta_link !== null ? field_cta_link.title : undefined}
      variant={field_reverse ? 'reverse' : undefined}
    >
      {children}
    </HeroCarousel>
  );
};

export default withRouter(Carousel);
