import React, {useContext} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';
import SiteContext from 'site/SiteContext';

import ObjectContainer from 'components/organisms/collection/ObjectContainer';
import { buildObjectManifestURL } from 'helpers/collection';

import BreadCrumbWithRouter from 'components/organisms/BreadCrumbWithRouter';
import { buildCrumbs } from 'helpers/breadcrumb';

import CollectionDisclaimer from 'components/organisms/paragraph/CollectionDisclaimer';

import { Loading } from 'dma-ui-kit';

const ObjectPage = ({  }) => {
    const { languagePath } = useContext(SiteContext);
    const { objectID } = useParams();

    const breadCrumbs = buildCrumbs(`/art/collection/object/${objectID}`);
    const endpoint = buildObjectManifestURL({ objectID });

    const { isLoading, error, data } = useQuery(['getSite', languagePath] , () =>
        axios(endpoint)
            .then(res => res.data)
    );

    if (isLoading) return <Loading />;

    if (error) {
        throw new Error(error);
    }

    return (
        <>
            <BreadCrumbWithRouter breadcrumbs={breadCrumbs} />
            <ObjectContainer data={data} />
            <CollectionDisclaimer />
        </>
    );
};

export default ObjectPage;
