import { withRouter } from 'react-router';
import { HomepageHero } from 'dma-ui-kit';

const HomePageHeroWidget = (props) => {
    const { history } = props;
    const { field_title_light, field_title_bold, field_subtitle, field_cta_link   } = props.paragraph.attributes;

    const { field_desktop_video, field_mobile_video, field_tablet_video } = props.paragraph.relationships;

    const desktop_video = field_desktop_video.data.relationships.field_media_video_file.data.attributes.uri.url;
    const tablet_video = field_tablet_video.data.relationships.field_media_video_file.data.attributes.uri.url;
    const mobile_video = field_mobile_video.data.relationships.field_media_video_file.data.attributes.uri.url;

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');
    const followCTA = () => { history.push(ctaLink); };
    
    return (
        <>
            <HomepageHero
                lightHeadline={field_title_light}
                boldHeadline={field_title_bold}
                description={field_subtitle}
                actionLabel={field_cta_link.title}
                action={followCTA}
                src={desktop_video}
                desktop={desktop_video}
                tablet={tablet_video}
                mobile={mobile_video}
            />
        </>
    );
};

export default withRouter(HomePageHeroWidget);
