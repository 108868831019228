import React from 'react';
import { Button, red } from 'dma-ui-kit';
import styled from 'styled-components';
import { withRouter } from 'react-router';

const Container = styled.div`
    padding: 0 12px;
    margin-bottom: 10px;

    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }
`;

const CTAButton = props => {
    const { history, paragraph } = props;
    const { field_cta_link } = paragraph.attributes;

    // If the link isn't relative, open in a new tab
    const ctaLink = field_cta_link.uri.replace('internal:', '');

    const followCTA = () => { 
        ctaLink.includes('http') ? window.open(ctaLink, "_blank") : 
        ctaLink.includes('mailto') ? window.location = ctaLink : 
        ctaLink.includes('#') ? window.location.hash = ctaLink.replace('#', '') :
        history.push(ctaLink); 
    };

    return (
        <Container>
            <Button variant="tertiary" color={red} label={field_cta_link.title} onClick={followCTA} />
        </Container>
    );
};

export default withRouter(CTAButton);