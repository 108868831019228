import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ContentBlock, ExhibitionsSection, H4, HomepageSectionHeader, Image, Tag } from 'dma-ui-kit';
import axios from 'axios';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import ImageNotFound from 'assets/resultmissing.png';

const HomePageExhibitionWidget = (props) => {
    const { history, paragraph } = props;
    const {field_cta_link, field_title_bold, field_title_light, field_subtitle} = paragraph.attributes;
    const [exhibitionItems, setExhibitionItems] = useState([]);

    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');
    const followCTA = () => { history.push(ctaLink); };

    const label = translateInterfaceText("View the exhibition");
    
    const showCurrentExhibitons = () => {
        const results = exhibitionItems.map((result, index) => {
            const image = (result.featured_image && result.featured_image[0]) ? result.featured_image[0] : ImageNotFound;
            const caption = `${formatInTimeZone(fromUnixTime(result.field_start_date), 'America/Chicago', 'MMM do, yyyy')} - ${formatInTimeZone(fromUnixTime(result.field_end_date), 'America/Chicago', 'MMM do, yyyy')}`;
            const title = (result.title) ? result.title : undefined;

            const url = (result.field_microsite_link && result.field_microsite_link[0]) ? result.field_microsite_link[0] : result.url[0];

            const content = (
                <ContentBlock 
                    headline={<Tag label={caption} />}
                    content={<H4 dark>{title}</H4>}
                    action={<Button variant="tertiary" color="white" label={label} />}
                />
            );

            return (
                <Link key={`exhibition_${index}`} to={{ pathname: url }} target={url.includes('http') ? '_blank' : null}>
                    <Image key={index} src={image} content={content} />
                </Link>                
            );
        });
        return results;
    };

    const getCurrentExhibitions = async () => {
        const date = Date.now() / 1000;
        const limit = 3;
        const query = 
        { 
            "size": `${limit}`, 
            "sort": 
            [ 
                { "promote": { "order" : "desc"} },
                { "field_start_date": { "order" : "asc" }}
            ], 
            "query": 
            { 
                "bool": 
                { 
                    "must": 
                    [ 
                        { "match": { "type": "exhibitions" } },
                        { "match": { "status": true }},
                        { "range" : { "field_end_date" : { "gte" : `${date}` } }}
                    ],
                    "should":
                    [
                        { "term": { "promote": "true" } } 
                    ]
                } 
            } 
        };
        const res = await axios.post(searchEndpoint, query);

        if (pullExhibitions)
        {
            const hits = res.data.hits.hits.map(hit => ({ ...hit._source }));
            setExhibitionItems(hits);
        }
    };

    let pullExhibitions = true;

    useEffect(() => {
        getCurrentExhibitions();
        return () => {
            pullExhibitions = false;
        };
    }, [pullExhibitions]);

    return (
        <>
            <ExhibitionsSection
                header={
                    <HomepageSectionHeader
                        boldHeadline={field_title_bold}
                        lightHeadline={field_title_light}
                        description={field_subtitle}
                        action={followCTA}
                        actionLabel={field_cta_link.title}
                    />
                }
            >
                {showCurrentExhibitons()}
            </ExhibitionsSection>
        </>
    );
};

export default withRouter(HomePageExhibitionWidget);
